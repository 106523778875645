import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import BaseLayout from '../components/templates/BaseLayout';
import Spacer from '../components/atoms/utility/Spacer';
import SEO from '../components/atoms/utility/seo';
import Headline from '../components/atoms/typography/Headline';
import Button from '../components/atoms/inputs/Button';

 const pillTagColors = [
    'border-secondary-orange',
    'border-secondary-blue',
    'border-secondary-green',
    'border-highlight',
    'border-default',
  ];


function FeaturedJob({featuredJob, spotlightImg}) {
  return (
    <>
      <h2 className="text-highlight text-3xl md:text-4xl lg:text-5xl mb-6 md:mb-8 lg:mb-10 text-center md:text-left">
        Featured Job
      </h2>
      <div className="lg:grid grid-cols-12">
          <Link
            to={featuredJob.frontmatter.slug}
            key=""
            className="col-span-7 mb-12 lg:mb-0 block"
          >
            <div className="border border-grey-100 group shadow-sm bg-opacity-100 hover:bg-highlight transition cursor-pointer rounded-3xl flex flex-col justify-between items-center py-5 lg:py-8 px-4 lg:px-12 lg:gap-10">
              <div className="text-grey-100 group-hover:text-white mb-4 lg:mb-0">
                <Headline
                  headerText={featuredJob.frontmatter.title}
                  className="!text-grey group-hover:!text-white transition mb-2 lg:mb-4 !text-2xl lg:!text-4xl" />
                <ul className="gap-5 w-full flex justify-start mb-4">
                  {featuredJob.frontmatter.tags.map((tag, index) => (
                    <li
                      key={tag}
                      className={`py-2 px-2 sm:px-6 border text-sm lg:text-base rounded-3xl ${pillTagColors[index]} group-hover:border-white transition`}
                    >
                      {tag}
                    </li>
                  ))}
                </ul>
                <p className="text-base md:text-lg lg:text-xl lg:line-clamp-3 transition ">
                  {featuredJob.frontmatter.description}
                </p>
              </div>
              <div className="flex h-full flex-col sm:items-start mt-auto sm:justify-start min-w-[200px] w-full">
                <Button label="Learn More" />
              </div>
            </div>
          </Link>

          <div className="col-span-4 col-start-9 text-center lg:text-left">
            <div className="flex justify-center lg:justify-start">
              <div className="w-40 h-40 rounded-3xl overflow-hidden shadow-md border-2 border-secondary-orange mb-4">
                <GatsbyImage
                  image={getImage(spotlightImg)}
                  alt="JJ Brewer a happy flockx employee smiling." />
              </div>
            </div>

            <h3 className="text-lg sm:text-xl md:text-2xl mb-4">
              Warren Bertram,{' '}
              <span className="text-highlight ">Product Designer</span>
            </h3>

            <p className="md:text-lg">
              “Here at flockx we thrive off sharing ideas, being collaborative and
              having the autonomy to be creative. We feel as though everyone has
              valuable ideas, but having feedback on these ideas is the{' '}
              <span className="text-highlight">key to success</span>
              ."
            </p>
          </div>
        </div>
      </>
  )
}

function CareersList({jobs}) { 
  return (
    <>
      <h2 className="text-3xl md:text-4xl lg:text-5xl md:leading-[1] lg:leading-[48px] mb-8 lg:mb-14 text-center sm:text-left text-grey">
        Current Openings
      </h2>
      <div id="careers-listings" className="flex flex-col gap-12">
        {jobs.nodes.map((job) => (
          <Link to={job.frontmatter.slug} key={`${job.frontmatter.slug}-link`}>
            <div className="border border-grey-100 group shadow-sm bg-opacity-100 hover:bg-highlight transition cursor-pointer rounded-3xl flex flex-col md:flex-row justify-between items-center py-5 lg:py-8 px-4 lg:px-12 lg:gap-10">
              <div className="text-grey-100 group-hover:text-white mb-4 lg:mb-0">
                <Headline
                  headerText={job.frontmatter.title}
                  className="!text-grey group-hover:!text-white transition mb-2 lg:mb-4 !text-2xl lg:!text-4xl"
                />
                <p className="text-base md:text-lg lg:text-xl lg:line-clamp-2 transition mb-4">
                  {job.frontmatter.description}
                </p>
                <ul className="gap-5 w-full flex justify-start">
                  {job.frontmatter.tags.map((tag, index) => (
                    <li
                      key={`${tag}`}
                      className={`py-2 px-2 sm:px-6 border text-sm lg:text-base rounded-3xl ${pillTagColors[index]} group-hover:border-white transition`}
                    >
                      {tag}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="flex h-full flex-col sm:items-end mt-auto sm:justify-end min-w-[200px] w-full">
                <Button label="Learn More" />
              </div>
            </div>
          </Link>
        ))}
      </div>
    </>
  )
}

function CareersPageHeroContent() {
  return (
    <div className="-mt-[15%] sm:mt-[5%] lg:mt-0">
      <h1 className="text-5xl sm:text-3xl md:text-4xl lg:text-6xl xl:text-[72px] leading-[1.3] md:leading-[1.4] xl:leading-[77px] font-light mb-2 sm:mb-4 md:mb-4 lg:mb-4 max-w-[390px] md:max-w-lg xl:max-w-xl mx-auto sm:mr-auto sm:ml-0 text-center sm:text-left text-white">
        Join our Team, Find your <span className="font-bold">flockx</span>
      </h1>

      <h2
        className={
          'text-lg sm:text-xl md:text-2xl xl:text-[32px] !leading-[1.6] md:leading-[1.3] lg:leading-[48px]' +
          ' ' +
          'mb-4 md:mb-4 lg:mb-8 text-white mx-auto font-light max-w-[300px] sm:max-w-sm lg:max-w-lg sm:mx-0 text-center sm:text-left leading-[1.3]'
        }
      >
        Become a part of our mission and help individuals{' '}
        <span className="font-bold">find their flock.</span>
      </h2>

      <div className="text-center sm:text-left">
        <a href="#careers-listings">
          <Button label="Explore Opportunities" id="platform-join-btn-2" />
        </a>
      </div>
    </div>
  );
}

export default function TeamPage({
  data: { jobs, heroImg, mobileHeroImg, featuredJob, spotlightImg },
}) {
  return (
    <BaseLayout
      heroContent={CareersPageHeroContent}
      mobileHeroImg={mobileHeroImg}
      heroImg={heroImg}
    >
      <SEO
        title="Careers"
        keywords={[
          'agentic',
          'team members',
          'ai agent',
          'ai agent collaboration',
          'multi ai agent',
          'personal ai agent',
          'mission statement',
        ]}
        description="Join the passionate team making it easy to manage, enhance, and enable collaboration with your own AI Agent. Grow your career and build your future with AI Agents!"
      />
      <Spacer sizeY={1} />

      {featuredJob && (
        <>
          <FeaturedJob  featuredJob={featuredJob} spotlightImg={spotlightImg}/>
          <Spacer sizeY={1} />
        </>
      )}

      {jobs && jobs.nodes?.length > 0 ? (
        <>
          <CareersList jobs={jobs} />
          <Spacer />
          <Spacer className="block lg:hidden" />
        </>
      ) : (
        <>
          No Current Openings
        </>
      )}
    </BaseLayout>
  );
}

const JobPropType = PropTypes.shape({
  frontmatter: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    tags: PropTypes.arrayOf(PropTypes.string).isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
});

TeamPage.propTypes = {
  data: PropTypes.shape({
    jobs: PropTypes.shape({
      nodes: PropTypes.arrayOf(JobPropType).isRequired,
    }).isRequired,
    heroImg: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        // NOTE(quinton): the gatsbyImageData prop is a complex object that is managed by Gatsby, and its structure can vary depending on the image processing options used. Therefore, it's often impractical to validate its structure with prop types.
        // eslint-disable-next-line react/forbid-prop-types
        gatsbyImageData: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired,
    mobileHeroImg: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        // eslint-disable-next-line react/forbid-prop-types
        gatsbyImageData: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired,
    featuredJob: JobPropType,
    spotlightImg: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        // eslint-disable-next-line react/forbid-prop-types
        gatsbyImageData: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

CareersList.propTypes = {
  jobs: PropTypes.shape({
    nodes: PropTypes.arrayOf(JobPropType).isRequired,
  }).isRequired,
};

FeaturedJob.propTypes = {
  featuredJob: JobPropType.isRequired,
  spotlightImg: PropTypes.shape({
    getImage: PropTypes.func.isRequired,
  }).isRequired,
};

export const query = graphql`
  query CareersPageQuery {
    heroImg: file(name: { eq: "careers-hero" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    mobileHeroImg: file(name: { eq: "careers-mobile-hero" }) {
      childImageSharp {
        gatsbyImageData
      }
    }

    jobs: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/content/jobs/" }
        frontmatter: { featuredPost: { ne: true } }
      }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        frontmatter {
          slug
          title
          description
          date
          tags
        }
      }
    }
    featuredJob: mdx(
      frontmatter: { featuredPost: { eq: true } }
      fileAbsolutePath: { regex: "/jobs/" }
    ) {
      frontmatter {
        title
        slug
        description
        tags
      }
      headerImage {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    spotlightImg: file(name: { eq: "Warren-Bertram" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, width: 300)
      }
    }
  }
`;
